import React from 'react';
import { FormGroup, ControlGroup, Colors } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import {
  RunwayNumbers,
  RunwayWidths,
  MaterialTypes,
  RunwayMarkings,
  RunwaySPACI,
  DistanceAvailable,
} from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function RunwayForm() {
  const { rwyspaci, rwyinuse, rwywidth, rwymaterial, rwymark, distavail }: any = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const updateRunway = React.useCallback(
    (e: any) =>
      dispatch(
        actions.rwyinuse.update(
            constructOption(e)
        ),
      ),
    [dispatch],
  );

  const updateWidth = React.useCallback(
    (e: any) =>
      dispatch(
        actions.rwywidth.update(
            constructOption(e)
        ),
      ),
    [dispatch],
  );

  const updateMaterial = React.useCallback(
    (e: any) => {
      dispatch(
        actions.rwymaterial.update(
            constructOption(e)
        ),
      );
    },
    [dispatch],
  );

  const updateMarkings = React.useCallback(
    (e: any) =>
      dispatch(
        actions.rwymark.update(
            constructOption(e)
        ),
      ),
    [dispatch],
  );

  const updateDistance = React.useCallback(
    (i: number, e: any) =>
      dispatch(
        actions.distavail.update({
          index: i,
          value: constructOption(e)
        }),
      ),
    [dispatch],
  );

    const updateSPACI = React.useCallback(
        (e: any) => dispatch(actions.rwyspaci.update(constructOption(e))), [dispatch]
    );

  const colorFromSPACI = (spaci: string): string => {
    switch(spaci) {
        case "Amber": {
          return Colors.ORANGE3;
        }

        case "Green": {
          return Colors.GREEN3;
        }

        case "Red": {
          return Colors.RED3;
        }

        default: {
          return '';
        }
    }
  }

  return (
    <>
      <ControlGroup className="iris-cgroup" fill>
        <FormGroup label="Runway in Use">
          <Select
            options={RunwayNumbers}
            value={rwyinuse?.value}
            onChange={updateRunway}
          />
        </FormGroup>

        <FormGroup label="Width">
          <Select
            options={RunwayWidths}
            value={rwywidth?.value}
            onChange={updateWidth}
          />
        </FormGroup>
      </ControlGroup>

      <ControlGroup fill>
        <FormGroup label="Material">
          <Select
            options={MaterialTypes}
            value={rwymaterial?.value}
            onChange={updateMaterial}
          />
        </FormGroup>

        <FormGroup label="Runway Markings">
          <Select
            options={RunwayMarkings}
            value={rwymark?.value}
            onChange={updateMarkings}
          />
        </FormGroup>
      </ControlGroup>

      <FormGroup label="Distance Available">
        <ControlGroup className="iris-cgroup" fill>
          <Select
            options={DistanceAvailable[0]}
            value={distavail[0]?.value}
            onChange={(e: any) => updateDistance(0, e)}
          />
          {[...Array(3)].map((_, i) => (
            <Select
              options={DistanceAvailable[1]}
              value={distavail[i + 1]?.value}
              onChange={(e: any) => updateDistance(i + 1, e)}
            />
          ))}
        </ControlGroup>
      </FormGroup>

      <ControlGroup fill>
        <FormGroup label="Runway SPACI">
          <Select backgroundColor={colorFromSPACI(rwyspaci?.value)} options={RunwaySPACI} value={rwyspaci?.value} onChange={updateSPACI} />
        </FormGroup>
      </ControlGroup>
    </>
  );
}

export default RunwayForm;
