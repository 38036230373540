import Nomad from "./applications/nomad";
import NomadForm from "./applications/nomad/form";
import NomadImport from "./applications/nomad/import";

import NomadLogo from "./applications/nomad/nomad.svg";

const getRoutes = () => [
  {
    type: "application",
    path: "/nomad",
    element: <Nomad />,
    img: NomadLogo,
  },
  {
    path: "/nomad/form",
    element: <NomadForm />
  },
  {
    path: "/nomad/import",
    element: <NomadImport />
  },
];

export default getRoutes;
