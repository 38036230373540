import { Card, CardList, Classes, EntityTitle } from "@blueprintjs/core";
import { ChevronRight } from "@blueprintjs/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Nomad() {
	const [importPanelIsOpen, openImportPanel] = useState(false);

	const navigate = useNavigate();

	const ImportPanel = () => {};

	return (
		<div className="nomad">
			<CardList className="nav-card-list" bordered>
				<Card interactive onClick={() => navigate("/nomad/form")}>
					<EntityTitle icon="edit" title="New form" />
					<ChevronRight className={Classes.TEXT_MUTED} />
				</Card>

				{/* <Card interactive onClick={() => navigate("/nomad/import")}>
					<EntityTitle icon="import" title="Import existing" />
					<ChevronRight className={Classes.TEXT_MUTED} />
				</Card> */}
			</CardList>
		</div>
	);
}

export default Nomad;
