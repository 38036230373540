import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Provider } from 'react-redux';
import store from './applications/nomad/store';

import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Hides that blue outline around elements UNLESS the tab key
// is pressed, beginning keyboard navigation.
import { FocusStyleManager } from "@blueprintjs/core";
FocusStyleManager.onlyShowFocusOnTabs();

import { BUILDNUMBER } from "./buildnumber.js";

// Create the application root
const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App meta={BUILDNUMBER} />
        </Provider>
    </React.StrictMode>,
);

// Register service worker
if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker
            .register("/service-worker.js")
            .then((registration) => {
                console.log("Service Worker registered:", registration);
            })
            .catch((error) => {
                console.log("Service Worker registration failed:", error);
            });
    });
}
