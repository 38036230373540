import {
	Button,
	ControlGroup,
	FormGroup,
	InputGroup,
	Intent,
	Label,
	Section,
	SectionCard,
} from "@blueprintjs/core";
import { intentClass } from "@blueprintjs/core/lib/esm/common/classes";
import { ChangeEvent, useReducer, useState } from "react";

function reducer(state: any, action: any) {
	switch (action.type) {
		case "set_recorded": {
			return {
				...state,
				recorded: action.recoreded,
			};
		}

		case "set_encrypted": {
			return {
				...state,
				encrypted: action.encrypted,
			};
		}

		default: {
			return state;
		}
	}
}

export default function NomadImport() {
	const [error, setError] = useState<string>();
	const [sequence, setSequence] = useState<string>();

	const [sequenceState, dispatch] = useReducer(reducer, {});

	const parseSequence = (e: ChangeEvent<HTMLInputElement>) => {
		const input = e.currentTarget.value;

		if (input.length < 50 || input.length > 80) {
			setError("Invalid length.");
			return;
		}

        // TODO parse logic

        setError(undefined);
	};

	return (
		<div className="nomad import-form">
			<Section title="Import" icon="import">
				<SectionCard>
					<FormGroup
						label="Code Sequence"
						helperText={error ?? undefined}
						intent={error ? Intent.DANGER : Intent.NONE}
					>
						<InputGroup
							large
							intent={error ? Intent.DANGER : Intent.NONE}
							onChange={parseSequence}
						/>
					</FormGroup>

					<ControlGroup vertical>
						<FormGroup inline label="Encrypted">
							<Label>{""}</Label>
						</FormGroup>

						<FormGroup inline label="Date Recorded">
							<Label>{""}</Label>
						</FormGroup>
					</ControlGroup>

					<ControlGroup fill>
						<Button icon="import" large text="Import" intent={Intent.PRIMARY} />
					</ControlGroup>
				</SectionCard>
			</Section>
		</div>
	);
}
