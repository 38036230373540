import React from "react";

import {
  Button,
  MenuItem,
  HTMLSelect,
  Intent,
  Classes,
  Colors,
} from "@blueprintjs/core";

import {
  Select as BlueprintSelect,
  type ItemRenderer,
  type ItemPredicate,
} from "@blueprintjs/select";

import classNames from "classnames";

const isMobileDevice =
  navigator.userAgent.includes("iPhone OS") ||
  navigator.userAgent.includes("Android");

const legacyOptionStructure = (options: Array<any>) => {
  if (Object.keys(options).length == 0) return []; // No options.

    // If [0].constructor !== Object
    // map to Object type
    if (options[1]?.constructor !== Object) {
        return Object.entries(options).map((v) => {
            return ({
                index: v[1],
                value: v[1],
            });
        })
    }

    return options.map((option: any) => ({
        index: option.label,
        value: option.value,
  }));
};

/**
 * "Legacy" Select component using the BlueprintJS HTMLSelect class which relies
 * exclusively on browser APIs. More performant but less pretty as a result.
 */
const LegacySelect = (props: any) => {
  // If no label is supplied in `props.options`, value will be used as the label.
  return (
    <HTMLSelect
      className="iris-select"
      key={props.key ?? Math.random().toString().substr(2, 8)}
      options={legacyOptionStructure(props.options ?? [])}
      onChange={props.onChange ?? undefined}
      defaultValue={props.defaultValue ?? undefined}
      value={props.value ?? undefined}
      large={props.large ?? true}
      fill={props.fill ?? true}
      disabled={props.disabled ?? false}
    />
  );
};

const itemPredicate: ItemPredicate<any> = (query, item, _index, exactMatch) => {
  const normTitle = String(item?.value).toLowerCase();
  const normLabel = String(item?.label).toLowerCase();
  const normQuery = query.toLowerCase();

  if (exactMatch) {
    return normTitle === normQuery || normLabel === normQuery;
  } else {
    return `${normLabel} ${normTitle}`.indexOf(normQuery) >= 0;
  }
};

const modernItemRenderer: ItemRenderer<string> = (
  item: any,
  { handleClick, handleFocus, modifiers, query }: any,
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.label}
      label={item.label === item.value ? "" : item.label}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={item.value}
    />
  );
};

const modernOptionStructure = (options: Array<any>) => {
  if (Object.keys(options).length == 0) return []; // No options.

  // If [0].constructor !== Object
  // map to Object type
  if (options[0]?.constructor !== Object) {
    return Object.values(options).map((v) => ({
      label: v,
      value: v,
    }));
  }

  return options;
};

const item_NoResults = (
  <MenuItem disabled={true} text="No options" roleStructure="listoption" />
);

/**
 * Modern, filterable, and informative Select component compared to the "Legacy" version
 * which relies exclusively on browser APIs.
 */
const Select = (props: any) => {
  return (
    <BlueprintSelect
      className="iris-select"
      items={modernOptionStructure(props.options ?? [])}
      itemRenderer={modernItemRenderer}
      itemPredicate={itemPredicate}
      onItemSelect={props.onChange ?? undefined}
      scrollToActiveItem={true}
      filterable={props.filterable || !isMobileDevice}
      resetOnClose={true}
      popoverProps={{ minimal: true }}
      noResults={item_NoResults}
      disabled={props.disabled ?? false}
    >
      <Button
        className="iris-selectbutton"
        style={{
          background: props.backgroundColor ?? '',
        }}
        rightIcon={props.rightIcon || "caret-down"}
        textClassName={classNames({
          [Classes.TEXT_MUTED]: props.value === " ",
        })}
        text={props.value ?? " "}
        large={props.large ?? true}
        alignText="left"
        ellipsizeText
        disabled={props.disabled ?? false}
      />
    </BlueprintSelect>
  );
};

const AutoElementSelector = (props: any) =>
  isMobileDevice ? <LegacySelect {...props} /> : <Select {...props} />;

export default Select;
export { LegacySelect, AutoElementSelector, isMobileDevice };
