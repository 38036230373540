import { useCallback } from "react";
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import { DistanceVisibility, CeilingTypes, CeilingAlts } from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function EnvironmentForm() {
  const { visibledist, ceiltype, ceilalt }: any = useSelector(
    (state: RootState) => state,
  );
  const dispatch = useDispatch();

  const updateVisibility = useCallback(
    (e: any) => dispatch(actions.visibledist.update(constructOption(e))),
    [dispatch],
  );

  const updateType = useCallback(
    (e: any) => dispatch(actions.ceiltype.update(constructOption(e))),
    [dispatch],
  );

  const updateAltitude = useCallback(
    (e: any) => {
        let alt: any = constructOption(e).value;

        let thou = Math.floor(alt / 1000);
        let hund = (alt % 1000) / 100;

        // TODO I shouldn't send multiple dispatches like this. Either use an
        // action creator or write a custom reducer to ingest both variables.
        dispatch(actions.ceilalt.update({ index: 0, value: { label: thou, value: thou } }));
        dispatch(actions.ceilalt.update({ index: 1, value: { label: hund, value: hund } }));
    },
    [dispatch],
  );

  return (
      <>
    <ControlGroup fill>
      <FormGroup label="Visible Distance">
        <Select
          options={DistanceVisibility}
          value={visibledist?.value}
          onChange={updateVisibility}
        />
      </FormGroup>

      <FormGroup label="Ceiling Alt.">
          <Select
              options={CeilingAlts}
              value={ceilalt[0]?.value * 1000 + (ceilalt[1]?.value * 100)}
              onChange={updateAltitude}
          />
      </FormGroup>
    </ControlGroup>

      <FormGroup label="Ceiling Type">
        <Select
          options={CeilingTypes}
          value={ceiltype?.value}
          onChange={updateType}
        />
      </FormGroup>
      </>
  );
}

export default EnvironmentForm;
