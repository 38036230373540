import { useCallback } from "react";
import { FormGroup, ControlGroup } from "@blueprintjs/core";
import { AutoElementSelector as Select } from "../../components/Select";

// Model
import { frequencyCodes } from "../../model";

// State imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { actions, constructOption } from "../../store/slices";

function FrequencyForm() {
  const frequency: any = useSelector((state: RootState) => state.freq);
  const dispatch = useDispatch();

  const updateFrequency = useCallback(
    (i: number, e: any) =>
      dispatch(
        actions.freq.update({
          index: i,
          value: constructOption(e),
        }),
      ),
    [dispatch],
  );

  return (
    <FormGroup label="Frequency">
      <ControlGroup className="iris-cgroup" fill>
        {[...Array(6)].map((_, i) => (
          <Select
            options={Object.values(frequencyCodes)}
            value={frequency[i]?.value}
            onChange={(e: any) => updateFrequency(i, e)}
            fill={false}
          />
        ))}
      </ControlGroup>
    </FormGroup>
  );
}

export default FrequencyForm;
