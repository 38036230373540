import {
	Button,
	ControlGroup,
	Intent,
	OverlayToaster,
	Position,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";

import ClipboardJS from "clipboard";

import { alphanum } from "../model";

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useNavigate } from "react-router-dom";

function CodePreview() {
	const [lastChanged, setLastChanged] = useState(new Date());

    const navigate = useNavigate();
	const state: RootState = useSelector((state: RootState) => state);

	const getReportDate = (): string => {
		return [
			lastChanged.getMonth(),
			lastChanged.getDate() - 1,
			lastChanged.getHours(),
			Math.round(lastChanged.getMinutes() / 2), // rounded to nearest even
		]
			.map((d) => alphanum[d])
			.join("");
	};

	const doRailFence = (pt: string, railCount: number = 6): string => {
		let rails: Array<any[]> = Array.from({ length: railCount }, (_, i) =>
			Array.from({ length: pt.length }),
		);
		rails = rails.map((r) => r.fill(undefined, 0, pt.length)); // fill rail with undefined for `pt.length`

		for (let i = 0; pt.length > i; i++) {
			// for each char in plaintext
			let iteration = i % railCount;
			let direction = Math.floor(i / railCount) % 2;
			let deviation = direction * (railCount - 1 - iteration - (1 + iteration));
			let rail = iteration + deviation;

			/* console.debug('i', i);
			 * console.debug('railCount', railCount);
			 * console.debug('Direction', direction);
			 * console.debug('Iteration', iteration);
			 * console.debug('Deviation', deviation);
			 * console.debug('Target rail', rail); */

			/* console.debug(i, `Math.floor(${i} / ${railCount}) =`, direction, "\r", `${i} % ${railCount} + ( (${round} % 2) * (${railCount} - ${i}) )`, rail, `(dev: ${deviation})`); */

			//console.debug(rail, i, pt[i]);

			/* rails[rail][i] = pt[i]; */

			rails.forEach((rail) => {
				/* console.debug((Math.random() * 9).toFixed(1), rail.map((c: any) => (!c ? '.' : c)).join('')); */
			});
		}

		return pt;
	};

	const undoRailFence = (ct: string, railCount: number = 6): string => {
		return ct;
	};

	const isMobileDevice: boolean =
		navigator.userAgent.includes("iPhone OS") ||
		navigator.userAgent.includes("Android");

	const toaster = OverlayToaster.createAsync({
		className: "iris-toast",
		position: isMobileDevice ? Position.BOTTOM : Position.TOP,
		maxToasts: 1,
		usePortal: true,
		canEscapeKeyClear: true,
	});

	const showToast = async (
		m: string,
		i: Intent | undefined,
		time: number = 2000,
	) => {
		(await toaster).show({
			message: m,
			intent: i,
			timeout: time,
		});
	};

	const copyToClipboard = async (e: any) => {
		const clipboard = new ClipboardJS(".iriscode-container", {
			text: () => doRailFence(unchunk(e.target.innerText)),
		});

		clipboard.on("success", async () => {
			await showToast("Text copied to clipboard.", Intent.SUCCESS);
			clipboard.destroy();
		});

		clipboard.on("error", async (e: any) => {
			await showToast("Failed to copy text to clipboard.", Intent.DANGER);
			console.error(e);
			clipboard.destroy();
		});
	};

	// Show notification if ClipboardJS is unsupported on this browser/platform.
	if (!ClipboardJS.isSupported()) {
		showToast("Clipboard interaction is unsupported.", Intent.DANGER);
	}

	const mapState = () => [
		state.location,
		state.afldstatus,
		state.afldwarn,
		state.rwyinuse,
		state.distavail,
		state.rwywidth,
		state.rwymaterial,
		state.rwyspaci,
		state.cbrpcn,
		(() =>
			(state.cbrpcn as any).label === "P"
				? [
						state.pcn,
						state.pvmttype,
						state.subgrade,
						state.tirepsi,
						state.pcnmethod,
					]
				: [state.cbr, state.cbrlayer])(),
		state.rcr,
		state.rff,
		state.winddir,
		state.windvel,
		state.gustvel,
		state.visibledist,
		state.ceiltype,
		state.ceilalt,
		state.temp,
		state.dewpoint,
		state.alt,
		state.rwymark,
		state.arff,
		state.gndcurrent,
		state.parkmog,
		state.workmog,
		state.mhe,
		state.medlevel,
		state.itw,
		state.freq,
		state.suitability,
	];

	const complexExtractLabel = (o: any, property: string = "label"): string => {
		// base case: if input is not an array, return the appropriate label or default value
		if (!Array.isArray(o)) {
			return o && o[property] !== undefined && o[property] !== null
				? o[property]
				: "*";
		}

		// recurse case: flatten the array by mapping each element through complexExtractLabel and joining the results
		return o.map((o2: any) => complexExtractLabel(o2)).join("");
	};

	const chunked = (str: string | string[], size: number = 5): string => {
		const rounds =
			Math.floor(str.length / size) + Math.ceil((str.length % size) / size);
		const chunks: string[] = [];

		str = [...str].join("");

		for (let i = 0; i <= rounds; i++) {
			const start = i * size;
			const part = str.substring(start, start + size);
			chunks.push(part);
		}

		return chunks.join(" ");
	};

	const unchunk = (chunked: string | string[], delim: string = " "): string => {
		const out = [...chunked].join("").split(delim);
		return out.join("");
	};

	useEffect(() => setLastChanged(new Date()), [state]);

    const handleClearAll = () => {
        window.location.reload();
    }

	return (
		<>
			<div onClick={copyToClipboard} className="iriscode-container">
				<span key={lastChanged.getTime() / 1000} className="iriscode noselect">
					{chunked([getReportDate(), ...complexExtractLabel(mapState())])}
				</span>
			</div>

			<div className="code-controls">
				<ControlGroup>
					<Button disabled icon="import" text="Import" onClick={() => navigate("/nomad/import")} />
					<Button icon="delete" text="Clear All" intent={Intent.DANGER} onClick={handleClearAll} />
				</ControlGroup>
			</div>
		</>
	);
}

export default CodePreview;
