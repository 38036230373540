//App.tsx
// SrA Matczak, Johnathon
// 2024-01-19
//

import React, { useState, useEffect } from "react";

// React router
import {
    createHashRouter,
    RouterProvider,
} from "react-router-dom";

import getRoutes from './routes';

import "./App.scss";
import "swiper/css";

import {
    OverlaysProvider,
} from "@blueprintjs/core";

// Create the Redux store
//const store = createStore();

import Landing from './applications/landing';

// Build routes from the RoutingTable
var routes = [
    {
        path: "/",
        element: <Landing />
    }
];

getRoutes().forEach(route => {
    routes.push({
        path: route.path,
        element: route.element
    });
});

// Create the router
const router = createHashRouter(routes);

function App(props: any) {
    console.log(props.meta);

    return (
        <div>
            <span className='meta-overlay'>{props.meta}</span>
            <OverlaysProvider>
                <RouterProvider router={router}  />
            </OverlaysProvider>
        </div>
    );
}

export default App;
